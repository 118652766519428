import React from "react";
import Slider from "react-slick";
import { Box, Grid, Typography } from "@mui/material";
import { theme } from "../themeV2";
import quotationMarks from "../images/quotation-marks.png";
import angleRight from "../images/angle-right.png";
import angleLeft from "../images/angle-left.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function CustomPrevArrow({ onClick }) {
  return <Box
    onClick={onClick}
    sx={{
      position: "absolute",
      left: 0,
      top: "50%",
      transform: "translateY(-50%)",
      zIndex: 2,
      cursor: "pointer",
    }}
  >
    <img src={angleLeft} alt="Anterior" style={{ width: "25px" }} />
  </Box>;
}

function CustomNextArrow({ onClick }) {
  return <Box
    onClick={onClick}
    sx={{
      position: "absolute",
      right: 0,
      top: "50%",
      transform: "translateY(-50%)",
      zIndex: 2,
      cursor: "pointer",
    }}
  >
    <img src={angleRight} alt="Siguiente" style={{ width: "25px" }} />
  </Box>;
}

function Comment({ text }) {
  return <Box
    display="flex"
    justifyContent="center"
    padding={2}
    position="relative"
  >
    <Box
      display="flex"
      justifyContent="center"
      sx={{
        position: "absolute",
        top: 0,
        zIndex: 1,
      }}
    >
      <img
        src={quotationMarks}
        alt="quotation marks"
        style={{ width: "40px" }}
      />
    </Box>
    <Box
      py={2}
      px={{ xs: 2, sm: 3 }}
      width={{ xs: "80%", sm: "90%" }}
      sx={{
        backgroundColor: theme.palette.colors.black.light,
        borderRadius: "14px",
        textAlign: "center",
        position: "relative",
      }}
    >
      <Typography variant="heading" fontWeight={400}>
        {text}
      </Typography>
    </Box>
  </Box>;
}

function CommentsSlider({ sliders }) {
  if (!sliders || sliders.length === 0) {
    return null;
  }

  const hasMultipleComments = sliders.length > 1;

  const sliderSettings = {
    dots: false,
    arrows: hasMultipleComments,
    infinite: hasMultipleComments,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    cssEase: "linear",
    pauseOnHover: false,
    prevArrow: hasMultipleComments ? <CustomPrevArrow /> : null,
    nextArrow: hasMultipleComments ? <CustomNextArrow /> : null,
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        {hasMultipleComments ? (
          <Slider {...sliderSettings}>
            {sliders.map((slider, index) => (
              <Comment key={index} text={slider.comment} />
            ))}
          </Slider>
        ) : (
          <Comment text={sliders[0].comment} />
        )}
      </Grid>
    </Grid>
  );
}

export default CommentsSlider;
